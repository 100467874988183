$(() => {
    // The exports is a fix for a bug with typescript whe module is set to 
    // none in the tsconfig https://github.com/Microsoft/TypeScript/issues/14351
    var exports = {};
    cssVars({watch: true});
    let subscriber = "client2.index";
    var $loadingContainer = $('#loadingContainer');
    var $layoutHeader = $('#layoutHeader');
    var $partialContainer = $("#partialContainer");
    messenger.register(subscriber, (message: any) => {

        if (message.action === "showView") {
            kendo.destroy(document.getElementById("partialContainer"));
            $partialContainer.html(message.payload.view);
        }
        else if (message.action === 'showLoading') {
            $layoutHeader.hide();
            $partialContainer.attr("aria-busy", "true");
            $partialContainer.hide();
            $loadingContainer.show();
            if (message.setFocus) {
                setFocus('#loading-msg');
            }
        }
        else if (message.action === 'hideLoading') {

            $layoutHeader.show();
            $partialContainer.attr("aria-busy", "false");
            $partialContainer.show();
            setFocus(message.setFocus);

            $loadingContainer.hide();
        } else if (message.action === "showError") {

            kendo.destroy(document.getElementById("mainContainer"));
            // render contents within the main container
            const mainContainerSelector = '#mainContainer';
            const mainContainer = $(message.payload.view).filter(mainContainerSelector);
            const mainContainerContent = mainContainer.html();

            $(mainContainerSelector).html(mainContainerContent);
            resetAnimation($(mainContainerSelector)[0]);
        } else {
            window.location = message.payload.redirectUrl;
        }
    });

    /**
     * In order to help keep screen reader users informed of changes to the partial container set focus to the view header
     * and add a tabindex so that it can only be focused by javascript only if not a full page reload
     */
    function setFocus(selector: string) {
        if (selector !== null &&
            performance.navigation.type !==
            performance.navigation.TYPE_RELOAD) {
            setTimeout(() => {
                    $(selector).attr("tabIndex", -1).focus();
                }, 500 )
            ;
        } 
    }
});